<template>
  <div>
    <header style="z-index: 999">
      <img alt="CMC logo" src="@/assets/images/logo-clearmortgagecapital.svg">
    </header>

    <main v-if="!ui.loading">
      <!--   Section for Tablets and Full Screens   -->
      <section class="d-md-block p-sm-normal">
        <div class="row">
          <div class="col-md-12 col-lg-4" :class="[docData.status !== 'signed' ? 'col-lg-4' : 'col-lg-12']">
            <div class="col-block bg-white shadow mb-5 sticky-top" style="top: 100px">
              <!--  Col Header  -->
              <div class="col-header w-100 border-bottom-light d-flex justify-content-between align-items-center">
                <div class="col-header-title">Sign Document</div>
              </div>

              <!--  Show if docs sign was saved  -->
              <div v-if="docData.status === 'signed'" class="col-body signing-complete">
                <img alt="Signing done" src="@/assets/icons/icon-normal-done.svg">
                <div class="signing-complete-title">Document Submitted</div>
                <div class="signing-complete-subtitle">
                  Thanks for authorizing and submitting your document.
                </div>
                <button class="btn btn-outline-grey" @click="downloadDoc" v-if="false">
                  Download your Copy (PDF)
                </button>
              </div>

              <!--  Show if docs sign wasn't saved  -->
              <div v-else class="col-body">
                <div class="form-group fullName-field">
                  <label for="fullNameField">Type your full name</label>
                  <input id="fullNameField" v-model="docData.signature"  @keypress="isLetter($event)" class="form-control" type="text">
                </div>
                <div class="form-group signature-preview">
                  <label for="signatureField">Your Signature (Preview)</label>
                  <p id="signatureField">{{ docData.signature }}</p>
                </div>
                <div class="form-check">
                  <input id="legalRepresentationField" v-model="info.legalRepresentation" class="form-check-input"
                         type="checkbox">
                  <label class="form-check-label" for="legalRepresentationField">
                    I agree this signature will be used as a legal representation of my signature.
                  </label>
                </div>
                <div class="form-check">
                  <input id="aboveDocumentField" v-model="info.aboveDocument" class="form-check-input" type="checkbox">
                  <label class="form-check-label" for="aboveDocumentField">
                    I’ve read and agree to the above document.
                  </label>
                </div>
                <div class="d-flex">
                  <BaseButton title="Authorize & Submit"
                              :disabled="!signingComplete"
                              :loading="ui.saving"
                              @click-btn="acceptSignature"
                              action="secondary-default" class="me-2" />
                  <BaseButton title="Decline"
                              :loading="ui.saving"
                              @click-btn="declineSignature"
                              action="secondary-default" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-8" v-if="docData.status !== 'signed'">
            <div class="col-block bg-white">
              <!--  Col Header  -->
              <div class="col-header w-100 border-bottom-light d-flex justify-content-between align-items-center">
                <div class="col-header-title">E-Sign Document</div>
              </div>

              <!--  Docs preview  -->
              <div v-for="i in numPages"
                   :key="i">
                <pdf :src="src" :page="i" class="page-preview" />
                <hr>
              </div>
              <!-- Acknowledgement -->
              <pdf :src="acknowledgementSource" :page="1" class="page-preview" />
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import BaseButton from '../../components/BaseButton.vue';
import {getBackendUrl} from "@/utils/backendUrl";

export default {
  name: 'ESign',
  components: {pdf, BaseButton},
  data() {
    return {
      src: null,
      numPages: null,
      acknowledgementSource: null,
      info: {
        legalRepresentation: false,
        aboveDocument: false,
      },
      docData: {
        status: 'pending'
      },
      ui: {
        loading: false,
        saving: false
      },
    }
  },
  beforeMount() {
    this.getData()
  },
  methods: {
    getData() {
      this.ui.loading = true
      this.$http.get(`/api/v1/e-sign/${this.$route.params.uuid}`)
        .then(response => {
          this.docData.status = response.data.status

          if (this.docData.status === 'declined') {
            this.$router.push({name: 'page_not_found'})
          }

          this.ui.loading = false
        })
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if(/^[A-Za-z\s]+$/.test(char)) { // Match with regex
        return true;
      } else { // don't add to input text
        e.preventDefault();
      }
    },
    acceptSignature() {
      this.ui.saving = true
      let formData = new FormData()
      formData.append('signature', this.docData.signature)
      formData.append('legalRepresentation', this.info.legalRepresentation)
      formData.append('aboveDocument', this.info.aboveDocument)

      this.$http.post(`/api/v1/e-sign/${this.$route.params.uuid}/sign`, formData)
        .then(response => {
          this.ui.saving = false
          this.ui.saved = true
          this.docData = response.data
        })
        .catch(() => {
          this.pushAlert('error','Something went wrong. Refresh page please and try again.')
        })
    },
    declineSignature() {
      if (confirm('Are you sure?')) {
        this.ui.saving = true
        this.$http.get(`/api/v1/e-sign/${this.$route.params.uuid}/decline`)
          .then(response => {
            this.ui.saving = false
            this.ui.saved = true
            this.docData = response.data
            this.pushAlert('success','Document declined successfully.')
            this.getData()
          })
          .catch(() => {
            this.pushAlert('error','Something went wrong. Refresh page please and try again.')
          })
      }
    },
    downloadDoc() {
      window.location = getBackendUrl() + `/api/v1/e-sign/${this.$route.params.uuid}/download`
    },
  },
  computed: {
    signingComplete: function () {
      return this.info.legalRepresentation === true && this.info.aboveDocument === true && this.docData.signature.length > 0;
    }
  },
  mounted() {
    this.src = pdf.createLoadingTask(getBackendUrl() + `/api/v1/e-sign/${this.$route.params.uuid}/get-preview`)
    this.src.promise.then((pdf) => {
      this.numPages = pdf.numPages;
      console.log('Generated')
    })

    this.acknowledgementSource = pdf.createLoadingTask(getBackendUrl() + `/api/v1/e-sign/${this.$route.params.uuid}/get-preview-acknowledgement`)
    this.acknowledgementSource.promise.then(() => {
      console.log('Generated acknowledgement')
    })
  }
}
</script>

<style lang="scss" scoped>
header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  background: white;
  text-align: center;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);

  @media screen and (min-width: 768px) {
    position: fixed;
    width: 100%;
  }

  @media screen and (max-width: 568px) {
    justify-content: end;
    padding: 0 12px;
    margin-bottom: 10px;
  }

  img {
    max-width: 180px;
    width: 100%;
    height: 48px;
  }
}

body {
  background-color: #F6F6F6;
}

main {
  background-color: #F6F6F6;
  @media screen and (min-width: 768px) {
    padding-top: 100px;
  }
  .p-sm-normal {
    padding: 30px;
  }

  .page-content {
    background: white;
    margin-bottom: 40px;

    &.bg-grey {
      background-color: #F6F6F6;
      height: 100vh;
      margin-bottom: 0;
    }
  }

  .col-block {
    margin: 0 10px;
    height: fit-content;

    .col-header {
      padding: 20px 30px;
      border-bottom: 1px solid #DADADA;

      span {
        color: rgba(0, 0, 0, .7);
        font-size: 14px;
        letter-spacing: 0;
        line-height: 21px;
      }

      &-title {
        color: #000000;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 30px;
      }
    }
  }

  .agree-btn {
    width: 100%;
    color: #FFFFFF;
    height: 48px;
    max-height: 48px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
    text-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
    font-weight: normal;
    background: linear-gradient(180deg, #065AE5 0%, #0552D1 100%);
    box-shadow: inset 0 -2px 0 0 #054BBD, inset 0 2px 1px 0 #1068F9;
  }

  .page-header {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 13px 16px;

    &-title {
      color: #000000;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
    }
  }

  .mobile-agree {
    position: fixed;
    bottom: 0;
    background: white;
    padding: 30px 24px;
    @media screen and (max-width: 568px) {
      button {
        margin: 0;
      }
    }
  }

  .fullName-field {
    max-width: 100%;
    margin-bottom: 24px;

    label {
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 18px;
      margin-bottom: 12px;
    }

    input {
      background-color: #FFFFFF;
      border-radius: 0;

      &::placeholder {
        color: rgba(0, 0, 0, .46);
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
      }
    }

  }

  .form-check {
    align-items: normal;
    margin-bottom: 18px;

    label {
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
      @media screen and (max-width: 568px) {
        display: flex;
        align-items: center;
        color: #000000;
        font-size: 13px;
        letter-spacing: 0;
        line-height: 21px;
      }
    }

    input {
      max-width: 18px;
      width: 18px;
      height: 18px;

      &:checked {
        background-color: #0d6efd;
        border-color: #0d6efd;
      }
    }
  }

  .insert-signature {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0 16px 30px 16px;
  }

  .signature-preview {
    p {
      color: #000000;
      font-family: "Safiar Signature";
      font-size: 42px;
      letter-spacing: 0;
      line-height: 36px;
      margin: 18px 0 30px 0;
    }
  }

  .col-body {
    padding: 30px;

    &.bg-white {
      background: white;
      height: 100vh;
    }

    &.signing-complete {
      text-align: center;

      .signing-complete-title {
        color: #000000;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        margin-top: 24px;
      }

      .signing-complete-subtitle {
        color: rgba(0, 0, 0, .7);
        font-size: 14px;
        letter-spacing: 0;
        line-height: 24px;
        margin-top: 12px;
        padding: 0 38px;
        margin-bottom: 36px;
      }
    }

    .btn-outline-grey {
      width: 100%;
      height: 48px;
      max-height: 48px;
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
      border: 1px solid rgba(0, 0, 0, .3);
      border-radius: 8px;
    }
  }

  .fixed-block {
    @media screen and (min-width: 1024px) {
      position: fixed;
      //right: 0;
    }
  }
}

#pdf-generate {
  max-width: 791px;
  width: 100%;
  height: 1000px;
}

.vue-html2pdf::v-deep {
  .content-wrapper {
    margin: 30px auto;

  }
}
</style>
